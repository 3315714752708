import type { RelatedContent } from "~/types/drupal_jsonapi";
import { createSignal, For, Show } from "solid-js";
import CardRelated from "~/components/Cards/CardRelated";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";

import "./RelatedContentComponent.css";

import IconKeyboardArrowLeft from "~/img/icons/keyboard_arrow_left.svg";
import IconKeyboardArrowRight from "~/img/icons/keyboard_arrow_right.svg";

export default function RelatedContentComponent(props: {
  contents: RelatedContent[];
}) {
  const items = () =>
    props.contents.filter(
      (content) => content.type !== "unknown" && content.status,
    );

  const [viewportSizeProvider] = useViewportSizeContext();

  const [currentSlideIndex, setCurrentSlideIndex] = createSignal(1);

  function goNext() {
    if (viewportSizeProvider.viewPortIsLessThan768) {
      if (currentSlideIndex() + 1 <= items().length) {
        setCurrentSlideIndex(currentSlideIndex() + 1);
      }
    } else {
      if (currentSlideIndex() + 1 <= Math.ceil(items().length / 3)) {
        setCurrentSlideIndex(currentSlideIndex() + 1);
      }
    }
  }

  function goPrevious() {
    if (currentSlideIndex() - 1 >= 1) {
      setCurrentSlideIndex(currentSlideIndex() - 1);
    }
  }

  return (
    <>
      <Show when={items().length > 0}>
        <section class="content-area related" data-test="related">
          <div class="inner">
            <h2 class="related-title">Sur le même sujet</h2>
            <div class="related-cards">
              <div class="related-slider">
                <div
                  class="slides"
                  style={{
                    "margin-left": -(currentSlideIndex() - 1) * 100 + "%",
                  }}
                >
                  <For each={items()}>
                    {(relatedContent) => (
                      <CardRelated content={relatedContent} />
                    )}
                  </For>
                </div>
              </div>
              <div
                class="related-nav"
                data-desktop-slides={Math.ceil(items().length / 3)}
                data-mobile-slides={items().length}
              >
                <button
                  onClick={() => goPrevious()}
                  class="arrow-left"
                  data-test="btn-previous"
                  aria-label="Précédent"
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconKeyboardArrowLeft />
                  </i>
                </button>
                <button
                  onClick={() => goNext()}
                  class="arrow-right"
                  data-test="btn-next"
                  aria-label="Suivant"
                >
                  <i aria-hidden="true" class="cog-icon">
                    <IconKeyboardArrowRight />
                  </i>
                </button>
              </div>
            </div>
          </div>
        </section>
      </Show>
    </>
  );
}
